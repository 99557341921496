// tags options
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import { renderSelectIn } from "../responsive_select";

document.addEventListener("DOMContentLoaded", () => {
  renderSelectIn("#patients-search-root");
  renderSelectIn("#practitioner-search-root");

  renderSelectIn("#patients-record-rep");
  renderSelectIn("#patients-visits-rep");
  renderSelectIn("#patients-financial-rep");
  renderSelectIn("#practitioners-financial-rep");
  renderSelectIn("#patients-medical-rep");
  renderSelectIn("#patients-prescription-rep");
});

document.addEventListener("appt-open-modal", () => {
  const [globalVarName] = renderSelectIn("#appointment-add-patients");
  document.addEventListener(
    "appt-close-modal",
    () => {
      delete window[globalVarName];
    },
    { once: true }
  );
});
